.driver_popup {
    left: 82%;
    top: 50%;
    position: absolute;  
    background: #fff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%), inset 0px 2px 2px rgb(0 0 0 / 5%);
    border-radius: 6px;
}

.driver_popup > ul > li { 
    padding: 0.5rem 2rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.approved_popup {
    left: 85%;
    top: 50%;
    position: absolute;  
    background: #fff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%), inset 0px 2px 2px rgb(0 0 0 / 5%);
    border-radius: 6px;
}

.approved_popup > ul > li { 
    padding: 0.5rem 2rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.side-driver {
    max-height: calc(100vh);
    width: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px solid #d2d2d2;
    background-color: #fff;
    padding: 20px;
    z-index: 5; 
}

.pop_over_driver {
    position: absolute;
    top: -200%;
    left: -160px;
    width: 170px;
}