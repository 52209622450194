table thead tr {  
    border-bottom: 1px solid #dfe0eb;
}
  
th { 
    background-color: #fff;  
    padding-bottom: 0.5rem/* 16px */;
    font-size: 1rem; 
    display: table-cell;
    vertical-align: bottom;
} 

td {
    padding: 20px 0;
    border-bottom: 1px solid #dfe0eb; 
    font-size: 0.875rem;
    line-height: 1.25rem;
    vertical-align: text-top;
} 
  
table tbody tr:hover {
    background-color: #f6f5f7;
}