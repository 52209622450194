.status_popup {
    left: 80%;
    top: 50%;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%), inset 0px 2px 2px rgb(0 0 0 / 5%);
    border-radius: 6px;
}

.status_popup > ul > li { 
    padding: 0.5rem 2rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}
 