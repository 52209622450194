.react-switch-bg {
    background-color: #bbb;
}

.react-switch-handle {
    outline: none !important;
    box-shadow: none !important;
}

.side-rate {
    max-height: calc(100vh);
    width: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px solid #d2d2d2;
    background-color: #fff;
    padding: 20px;
    z-index: 5;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px; 
}

.status_rate {
    left: 90%;
    top: 50%;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%), inset 0px 2px 2px rgb(0 0 0 / 5%);
    border-radius: 6px;
}

.status_rate > ul > li { 
    padding: 0.5rem 2rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}
 