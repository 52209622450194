.popup {
    background-color: #fff; 
    /* position: absolute; */
    /* bottom: 120%; */
    /* bottom: -68%;
    left: 77.8%;  */
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 95%;
    margin-left: 5%;
 }