.login_logo {
    height: 130px;
    margin: 30px auto;
} 

.login_icon { 
    height: 22px;
    margin: auto;
    position: absolute;
    margin: 9px;
}

.centercontainer {
    width: 20rem;
    z-index: 1;
}