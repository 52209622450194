.css-viou3o-MuiAutocomplete-root{
    width: 100% !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #383838 !important;
}
 
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline { 
    border-color: #383838 !important;
}

.css-1na68kg {
    width: 100% !important;
} 

.css-u9osun.Mui-focused {
    color: #383838 !important;
}

.react-datepicker__input-container input {
    background: transparent;
    cursor: auto;
}